export function removeCookiesFromSocketRequests() {
  // Monkey-patch XMLHttpRequest to remove cookies from requests

  const originalOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (
    method,
    url,
    async,
    user,
    password
  ) {
    this._url = url;
    originalOpen.call(this, method, url, async, user, password);
  };

  const originalSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function (body) {
    if (this._url && this._url.includes('socket.io')) {
      // Remove cookies by setting withCredentials to false
      this.withCredentials = false;
    }
    originalSend.call(this, body);
  };
}
