import cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import { AnalyticsBrowser } from '@segment/analytics-next';
import mixpanel from 'mixpanel-browser';
import { LocationState } from 'history';
import { isImpersonationSession } from '../../helpers/auth/cognito';
import { categories, PAGE_VIEWED } from '../../constants/analytics';
import { convertPathToName } from '../../libs/formatting';
import config from '../../../app-config';

enum SegmentConsentState {
  Indeterminate,
  Allow,
  Deny,
}

let segmentClient = new AnalyticsBrowser();
let segmentConsentState = SegmentConsentState.Indeterminate;

export const getSegmentAnonymousId = (): string | undefined =>
  segmentClient.instance?.user().anonymousId() ??
  cookies.get('ajs_anonymous_id');

const getSegmentConsentState = (): SegmentConsentState => {
  if (window.cookiehub?.hasAnswered()) {
    return window.cookiehub?.hasConsented('analytics')
      ? SegmentConsentState.Allow
      : SegmentConsentState.Deny;
  }

  return SegmentConsentState.Indeterminate;
};

const initSegmentClient = () => {
  segmentConsentState = getSegmentConsentState();

  switch (segmentConsentState) {
    case SegmentConsentState.Allow:
      return segmentClient.load({ writeKey: config.segmentKey });
    case SegmentConsentState.Deny:
      return segmentClient.load(
        { writeKey: config.segmentKey },
        { disableClientPersistence: true }
      );
    default:
  }
};

export const updateSegmentConsentState = () => {
  const newConsentState = getSegmentConsentState();

  if (newConsentState === segmentConsentState) {
    // No need to handle consent state update
    return;
  }

  segmentConsentState = newConsentState;

  const isSegmentInitialised = segmentClient.instance?.initialized ?? false;

  if (isSegmentInitialised) {
    // Reset client to load new consent state at runtime
    segmentClient = new AnalyticsBrowser();
  }

  initSegmentClient();
};

export const trackEvent = async (event, properties) => {
  const extendedProperties = {
    ...properties,
    'User Agent': navigator.userAgent,
    isImpersonationSession: isImpersonationSession(),
  };

  // eslint-disable-next-line no-unused-expressions
  window?.userpilot?.track(event, extendedProperties);

  return new Promise((resolve) => {
    segmentClient.track(event, extendedProperties, {}, resolve);
  });
};

export const trackAlias = (userId) => {
  segmentClient?.alias(userId);
};

export const trackIdentity = (id, traits) => {
  const extendedTraits = {
    ...traits,
    'User Agent': navigator.userAgent,
    isImpersonationSession: isImpersonationSession(),
  };

  segmentClient?.identify(id, extendedTraits);

  // eslint-disable-next-line no-unused-expressions
  window?.userpilot?.identify(id, extendedTraits);
};

export async function trackPageViewed(location: LocationState) {
  const { pathname, search } = location;
  const page = `${pathname}${search}`;

  ReactGA.set({ page });

  const category = categories.find(({ regex }) => pathname.match(regex));

  if (!category) return;

  const pageViewed = {
    Category: category.type,
    'Page Name': category.pageName || convertPathToName(pathname),
    'Page URL': pathname,
    Platform: 'Web',
  };

  // if (category.increment) {
  //   pageViewed['# of Profiles Viewed'] = 1;
  // }

  await trackEvent(PAGE_VIEWED, pageViewed);
}

export const mixpanelAlias = (userId) => {
  mixpanel.alias(userId);
};

export const resetAnalytics = (): void => {
  segmentClient?.reset();
  mixpanel.reset();

  cookies.remove('_cioanonid');
  cookies.remove('_cioid');
  cookies.remove('ajs_anonymous_id');
  cookies.remove('ajs_user_id');

  localStorage.removeItem('ajs_user_traits');
  localStorage.removeItem('ajs_anonymous_id');
  localStorage.removeItem('ajs_user_id');
};

export const initGA = (): void => {
  if (!window.GA_INITIALIZED && process.env.googleAnalyticsId) {
    ReactGA.initialize(process.env.googleAnalyticsId, {});

    window.GA_INITIALIZED = true;
  }
};

export const setCookie = (cookieName: string, cookieValue: string): void => {
  const existingCookie = cookies.get(cookieName);

  if (!existingCookie || existingCookie !== cookieValue) {
    cookies.set(cookieName, cookieValue, {
      ...(process.env.cookieSecure && {
        secure: Boolean(process.env.cookieSecure),
      }),
      expires: 21,
    });
  }
};
